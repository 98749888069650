import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {graphql} from 'gatsby'
import TogetherImg from '../../assets/images/clusters/together.webp'
import ImportantImg from '../../assets/images/clusters/important.webp'
import ImportantMImg from '../../assets/images/clusters/important-m.webp'
import OrganizationImg from '../../assets/images/clusters/organization.webp'
import { Link } from 'gatsby'

const Clusters = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='Clusters | BlueMail App' 
                postDescription='Clusters instantly spring into action and elegantly bring together People, Groups, and Services.'
                postImage=''
                postURL='features-functions/clusters'
                postSEO
            />
            <Navbar />
            <div className='clusters-features'>
              <div style={{background: "linear-gradient(180deg, #D4E3FC 0%, #F0F5FE 100%)"}}>
                <div className='container-fluid header'>                
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-6 col-md-12 col-xs-12'>
                        <h1><span>Clusters</span></h1>
                        <p>Do you ever get annoyed by the amount of clutter in your inbox? At BlueMail, we have a 
                          solution we think will work for you. It’s called Clusters, and we think it makes your user 
                          experience a smooth one.</p>
                      </div> 
                      <div className='col-lg-6 col-md-12 col-xs-12 sSs p-0'>                        
                        <img className='organiz-img' src={OrganizationImg} alt='organization image' />  
                      </div>                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='container clusters-features pt-100'>
              <div className='row align-items-center m-rev'>
                <div className='col-lg-6 col-md-6 col-xs-12 p-0'>
                  <img className='w-85' src={TogetherImg} alt='together image' />
                </div>
                <div className='col-lg-6 col-md-6 col-xs-12'>
                  <h2>Some Emails Just Go <span>Together</span></h2>
                  <p>Our email is smart, so smart in fact, it organizes your email by categorizing known senders into 
                    smart clusters. It works by organizing your communication from known senders into a sub-folder 
                    automatically. You never have to worry about managing this feature manually.</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12 text-center pt-120'>
                  <h2>Reminding You of What Is <span>Important</span></h2>
                  <p>We all experience busy days and sometimes, important things fall through the cracks. When an email 
                    joins a cluster, this acts as a reminder for you about previous emails you might’ve missed. So, as 
                    soon as you receive a new email from that particular service or person, the cluster pops up to the 
                    top of your inbox.</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12 pt-100 pb-10'>
                  <img className='des' src={ImportantImg} alt=' important notification desktop' />
                  <img className='sSs' src={ImportantMImg} alt=' important notification mobile' />
                </div>
              </div>
              
              <div className='row'>
                <Link to='/blog/clusters-view/'>
                  <div className='col-lg-12 col-md-12 col-xs-12 text-center pt-100 pb-100'>
                    <h2>Clusters <span>instantly</span> spring into action</h2>
                    <p>Elegantly bring together People, Groups, and Services. Emails are stacked with all previous emails from the same sender or group. 
                    Clusters remove the clutter to create a new context-based view to see all previous communications exchanged
                    and help you navigate your inbox much more efficiently.</p>
                  </div>
                </Link>
              </div>    
            </div>
            
            <Footer />
        </Layout>
    )
}

export default Clusters

export const query = graphql`
query ClustersPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
